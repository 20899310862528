<template>
  <div
    v-if="newCarousel"
    class="yf-new-product-carousel"
  >
    <div class="yf-new-product-heading">
      <h2>{{ newCarousel.data.heading }}</h2>
      <p>{{ newCarousel.data.description }}</p>
    </div>
    <template v-if="$fetchState.pending">
      <SfCarousel
        :settings="{
          type: 'carousel',
          perView: 3,
          hoverpause: true,
          breakpoints: {
            479: {
              perView: 1,
              peek: {
                before: 0,
                after: 35,
              },
            },
          },
        }"
        class="carousel new-product-list"
      >
        <SfCarouselItem
          class="carousel__item yf-new-product-item carousel-content-placeholder"
        >
          <content-placeholders class="yf-new-product__image">
            <content-placeholders-img />
          </content-placeholders>
        </SfCarouselItem>
        <SfCarouselItem
          class="carousel__item yf-new-product-item  carousel-content-placeholder"
        >
          <content-placeholders class="yf-new-product__image">
            <content-placeholders-img />
          </content-placeholders>
        </SfCarouselItem>
        <SfCarouselItem
          class="carousel__item yf-new-product-item carousel-content-placeholder"
        >
          <content-placeholders class="yf-new-product__image">
            <content-placeholders-img />
          </content-placeholders>
        </SfCarouselItem>
      </SfCarousel>
    </template>
    <template v-else>
      <SfCarousel
        v-if="toShow"
        :settings="{
          type: 'carousel',
          perView: 3,
          hoverpause: true,
          gap: 2,
          breakpoints: {
            479: {
              perView: 1,
              peek: {
                before: 0,
                after: 35,
              },
            },
          },
        }"
        class="carousel new-product-list"
      >
        <SfCarouselItem
          v-for="(product, i) in mappedProducts"
          :key="i"
          :class="'carousel__item yf-new-product-item item-' + i"
        >
          <div
            v-if="product && product.isMoreItem === false"
            class="yf-new-product-content"
          >
            <CustomProductCard
              :image="getProductThumbnailImage(product, 500, 'j')"
              :image-alt="getProductImageAlt(product, 'thumbnail')"
              :link="localePath(buildProductUrl(product))"
              :colors="getSfColorsFromAttributes(getProductColorAttribute(product))"
              :title="getProductName(product)"
              :regular-price="$n(getProductPrice(product).regular, 'currency')"
              :special-price="getProductPrice(product).special && $n(getProductPrice(product).special, 'currency')"
              :omnibus-price="product.omnibus_price ? $n(product.omnibus_price,'currency') : null"
              :is-added-to-cart="true"
              :add-to-cart-disabled="false"
              :show-swatches="showSwatches"
              image-loading="eager"
              :sku="getProductMerchantSku(product)"
              :category="product && product.categories ? product.categories[0].name : ''"
              :full-price-gtm="getPriceFixedDecimals(getProductPrice(product).regular)"
              :price-gtm="getPriceFixedDecimals(getProductPrice(product).special) || getPriceFixedDecimals(getProductPrice(product).regular)"

              :is-in-wishlist="!!itemsInWishlist[product.sku]"
              @click:wishlist="toggleWishlist(product)"
            />
          </div>
          <div
            v-if="product.isMoreItem === true && newCarousel && newCarousel.data"
            class="yf-new-product-content non-product"
          >
            <div class="yf-new-product__image">
              <SfLink
                :link="newCarousel.data.moreList.link"
                class="yf-new-product__link"
              >
                <nuxt-img
                  class="yf-new-product__image-img"
                  format="webp"
                  :src="newCarousel.data.moreList.picture"
                  sizes="sm:100vw md:50vw lg:400px"
                  width="510"
                  height="495"
                  preload
                  loading="eager"
                />
              </SfLink>
            </div>
            <SfLink
              :link="newCarousel.data.moreList.link"
            >
              <div class="yf-new-product__ticket">
                <div class="ticket-border">
                  <h3>{{ newCarousel.data.moreList.heading }}</h3>
                </div>
              </div>
            </SfLink>
          </div>
        </SfCarouselItem>
      </SfCarousel>
    </template>
  </div>
</template>

<script>
import {
  SfCarousel,
  SfLink,
} from '@storefront-ui/vue';

import {
  contentGetters, productGetters, useContent, useProduct,
} from '@vue-storefront/gemini';
import {
  defineComponent, ref, useFetch, watch,
} from '@nuxtjs/composition-api';
import { getCategoryUidFilter } from '@vue-storefront/gemini/src/getters/categoryGetters';
import { useUrlHelpers } from '~/composables';
import { useI18n } from '~/helpers/hooks/usei18n';
import { getSfColorsFromAttributes, getProductColorAttribute, getPriceFixedDecimals } from '~/helpers/product/productData';
import useWishlist from '~/composables/useWishlist';
import CustomProductCard from './CustomProductCard';

export default defineComponent({
  name: 'NewProductsCarousel',
  components: {
    SfCarousel,
    SfLink,
    CustomProductCard,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    loading: Boolean,
    showSwatches: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  setup() {
    const { buildProductUrl } = useUrlHelpers();
    const toShow = ref(true);
    const { locale } = useI18n();
    const identifierHomePageNewCarousel = `NewProductsCarousel-${locale}`;
    const newCarousel = ref(false);
    const mappedProducts = ref([]);
    const { itemsInWishlist, toggleWishlist, loadItemsFromList } = useWishlist(mappedProducts);
    const {
      blocks,
      loadBlocks,
    } = useContent(`cmsBlockNewProductsCarousel${identifierHomePageNewCarousel}`);

    const {
      products,
      search: newProductsSearch,
    } = useProduct('newProducts');

    const newProductsSearchFilter = {};

    const { fetchState } = useFetch(async () => {
      await loadBlocks({ identifiers: [identifierHomePageNewCarousel] });
      newCarousel.value = contentGetters.getCmsBlockContent(blocks.value)[identifierHomePageNewCarousel];
      const newCarouselCategoryUid = newCarousel.value?.data?.category_uid ?? '';
      if (newCarouselCategoryUid) {
        newProductsSearchFilter.category_uid = getCategoryUidFilter(
          String(`grn:product-list:product-list::${newCarouselCategoryUid}`),
        );
      }
      await newProductsSearch({
        pageSize: 15,
        currentPage: 1,
        sort: {
          position: 'ASC',
        },
        filter: newProductsSearchFilter,
      });
      let list = products.value.items.map((product) => ({
        // @ts-ignore
        isMoreItem: false,
        ...product,
      }));
      await loadItemsFromList(list);
      list = [
        ...list.slice(0, 1),
        {
          isMoreItem: true,
        },
        ...list.slice(1),
      ];
      mappedProducts.value = list;
    });

    // Force update of the SfGallery to force recalculation of its internal perView
    // that being base on $slots doesn't play well with composition!
    watch(toShow, () => {
      if (toShow.value === false) {
        toShow.value = true;
      }
    });
    watch(mappedProducts, () => {
      if (toShow.value === true) {
        toShow.value = false;
      }
    });

    watch(fetchState, () => {
      if (toShow.value === true) {
        toShow.value = false;
      }
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const getProductName = (product) => productGetters.getName(product);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const getProductPrice = (product) => productGetters.getPrice(product);

    return {
      blocks,
      loadBlocks,
      newCarousel,
      mappedProducts,
      buildProductUrl,
      toShow,
      getSfColorsFromAttributes,
      getProductColorAttribute,
      getPriceFixedDecimals,
      getProductThumbnailImage: productGetters.getProductThumbnailImage,
      getProductName,
      getProductPrice,
      getProductMerchantSku: productGetters.getProductMerchantSku,
      getProductImageAlt: productGetters.getProductImageAlt,
      toggleWishlist,
      itemsInWishlist,
    };
  },
});
</script>

<style lang='scss' scoped>
.section {
  margin-top: var(--spacer-base);
}

.carousel-content-placeholder .vue-content-placeholders-img {
  min-height: 600px;
}

.carousel {
  margin: 0 calc(-1 * var(--spacer-sm)) 0 0;
  @include for-desktop {
    margin: 0;
  }

  &__item {
    margin: 1.9375rem 0 2.4375rem 0;
  }
}
</style>

<style lang='scss'>
.yf-new-product-carousel {
  max-width: 1414px;
  margin: 0 auto;
  padding-right: 72px;

  .non-product {
    position: relative;
    padding-bottom: 150%;
    height: 0;
    overflow: hidden;
  }

  .yf-new-product__ticket {
    height: 100%;
    left: 0;
    padding: 20%;
    position: absolute;
    top: 0;
    width: 100%;

    .ticket-border {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      text-align: center;
      width: 100%;

      h3 {
        color: #fff;
        font-family: 'Frank Ruhl Libre';
        font-size: 40px;
        font-weight: 400;
        letter-spacing: 1px;
        line-height: 54px;
        padding: 22.5%;
      }
    }
  }

  // style carousel
  .new-product-list {
  }

  .sf-carousel {
    width: 100%;
    max-width: 100%;
    padding: 0;

    &__wrapper {
      max-width: calc(100% - 72px);
      width: 100%;
      margin-left: auto;
      margin-right: 0;
    }

    &__controls {
      z-index: 1;
    }

    .sf-carousel-item {
      padding: 0 12px;
      max-height: 100vh;

      .sf-product-card {
        --yf-product-content-transform: translateX(-100%);
        --yf-product-content-opacity: 0;
        --yf-product-content-visibility: hidden;
        --yf-product-content-events: none;
        --product-card-wishlist-icon-opacity: 0;

        &:hover {
          --product-card-box-shadow: unset;
          --yf-product-content-transform: translateX(0);
          --yf-product-content-opacity: 1;
          --yf-product-content-visibility: visible;
          --yf-product-content-events: all;
          --product-card-wishlist-icon-opacity: 1;
        }
      }

      .yf-product-content {
        padding: 0 17px 5px 17px;
        position: absolute;
        bottom: 0;
        left: 0;
        transform: var(--yf-product-content);
        transition: all .2s ease-in-out;
        opacity: var(--yf-product-content-opacity);
        visibility: var(--yf-product-content-visibility);
        pointer-events: var(--yf-product-content-events);
        z-index: 1;

        .sf-product-card__title {
          margin: 9px 0 0 0;
        }

        .sf-product-card__price {
          margin: 3px;
          .sf-price {
            &__regular,
            &__old,
            &__special {
              z-index: 1;
            }
          }
        }
      }
    }
  }

  .yf-new-product-heading {
    margin-bottom: 48px;
    margin-top: 94px;
    margin-left: 0;
    text-align: center;

    h2 {
      color: #141e16;
      font-family: 'Frank Ruhl Libre';
      font-size: 50px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 46px;
      margin-bottom: 14px;
      text-align: center;
    }

    p {
      color: #141e16;
      font-family: 'Spartan';
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 25px;
      margin: 0;
    }
  }

  .glide__slide--clone {
    visibility: hidden;
    // height: max-content;
    .yf-new-product-content {
      display: none;
    }
  }

  .non-product {
    img {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);
      object-fit: cover;
    }
  }

  .yf-new-product__link {
    position: absolute;
    height: auto;
    width: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .yf-new-product__ticket {
    height: 100%;
    left: 0;
    padding: 20.7% 20.6% 21.7%;
    position: absolute;
    top: 0;
    width: 100%;

    .ticket-border {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      text-align: center;
      width: 100%;

      h3 {
        color: #fff;
        font-family: 'Frank Ruhl Libre';
        font-size: 40px;
        font-weight: 400;
        letter-spacing: 1px;
        line-height: 54px;
        padding: 22.5% 21.7%;
      }
    }

    img {
      width: 100%;
    }
  }

  .sf-carousel__controls .sf-arrow.sf-button span {
    display: none;
  }

  .sf-carousel__controls .sf-arrow.sf-button:before {
    display: inline-block;
    font-size: 30px;
    line-height: 44px;
    position: relative;
    z-index: 2;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  .sf-carousel__controls {
    pointer-events: none;
    width: calc(100% + 72px);

    .sf-arrow {
      background-color: transparent;
      width: 55px;
      height: 55px;
      pointer-events: all;

      &.sf-button[aria-label='next']:before {
        background-image: url('https://cdn.lazzarionline.com/626fda60dac21c9b7d38edd2.svg');
      }

      &.sf-button[aria-label='previous']:before {
        background-image: url('https://cdn.lazzarionline.com/626fda63dac21c9b7d38edd8.svg');
      }

      &.sf-button {
        color: var(--c-light);
        fill: var(--c-light);
        padding: 0;
        --button-background: transparent;
        --button-box-shadow-opacity: 1;
        --button-box-shadow: none;
      }
    }
  }
}

@media (max-width: 768.98px) {

  .yf-new-product-carousel {
    padding-right: 24px;

    .sf-carousel__wrapper {
      max-width: calc(100% - 19px);
    }

    .sf-carousel {
      .sf-carousel-item {
        padding: 0 5px;
        margin-bottom: 24px;
        margin-top: 24px;

        .yf-custom-product-card {
          .sf-product-card {
            --product-card-box-shadow: unset;
            --yf-product-content-transform: translateX(0);
            --yf-product-content-opacity: 1;
            --yf-product-content-visibility: visible;
            --yf-product-content-events: all;
            --product-card-wishlist-icon-opacity: 1;
          }
        }
      }
    }

    .yf-new-product-heading {
      padding-left: 24px;
      padding-right: 24px;
      margin-top: 42px;
      margin-bottom: 0;
      text-align: unset;

      h2 {
        font-size: 28px;
        line-height: 34px;
        text-align: unset;
      }

      p {
        font-size: 12px;
        text-align: unset;
      }
    }

    .yf-new-product__ticket {
      .ticket-border h3 {
        font-size: 28px;
      }
    }
  }
}

@media (max-width: 1320px) {
  .yf-new-product-carousel .sf-carousel__controls {
    display: none;
  }
}

@media (max-width: 1023px) {
  .yf-new-product-carousel .sf-color-picker__label.smartphone-only,
  .yf-new-product-carousel .sf-color-picker__close {
    display: none;
  }

  .yf-new-product-carousel .sf-overlay {
    background: #fff;
    z-index: -1;
  }
}
</style>
